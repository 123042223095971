import React, { useEffect } from 'react';

const SaleBanner = () => {
    useEffect(() => {
        // Redirect after 5 seconds
        const timer = setTimeout(() => {
            window.location.href = 'https://newlookbodyworks.com/'; // Change this to your desired URL
        }, 10000);

        // Cleanup the timer if the component unmounts before the timeout
        return () => clearTimeout(timer);
    }, []);

    return (
        <div style={styles.banner}>
            <h1 style={styles.text}>This Domain Is Up for Sale</h1>
        </div>
    );
};

const styles = {
    banner: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f8d7da',
    },
    text: {
        color: '#721c24',
        fontFamily: 'Arial, sans-serif',
    },
};

export default SaleBanner;
